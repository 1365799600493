import "./index.css";
import React from "react";
import AnimatedLetters from "../AnimatedLetters";
import { useState, useEffect } from "react";

const SideProjectsHome = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = [
    "S",
    "i",
    "d",
    "e",
    " ",
    "P",
    "r",
    "o",
    "j",
    "e",
    "c",
    "t",
    "s",
  ];

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 2500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <div className="container">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={10}
          />
        </h1>
        <div id="sideProjects" className="row"></div>
      </div>
    </div>
  );
};

export default SideProjectsHome;
